<template>
  <div class="home">
    <div class="dataContent flex flex-column flex-1">
      <div class="dataTop">
        <ul>
          <li>
            <header>
              <p>SOS报警</p>
              <span>(最近一个月)</span>
            </header>

            <div class="dataBox">
              <div class="yeasList">
                <div v-for="(sos,index) in sosList" :key="index">{{sos.roomNumber}}房</div>
                <p class="noData" v-if="sosList.length==0">暂无报警</p>
              </div>
              <div class="data_charts">
                <div id="breadEcharts0"></div>
              </div>
            </div>
          </li>
          <li>
            <header>
              <p>请求清理</p>
              <span>(最近一天)</span>
            </header>
            <div class="dataBox">
              <div class="yeasList">
                <div
                  v-for="(cleanItem,index) in cleanNumList"
                  :key="index"
                >{{cleanItem.roomNumber}}房</div>
                <p class="noData" v-if="cleanNumList.length==0">暂无请求</p>
              </div>
              <div class="data_charts">
                <div id="breadEcharts1"></div>
              </div>
            </div>
          </li>
          <li>
            <header>
              <p>门磁报警</p>
              <span>(实时)</span>
            </header>
            <div class="dataBox">
              <div class="yeasList">
                <div v-for="(doorItem,index) in doorList" :key="index">{{doorItem.roomNumber}}房</div>
                <p class="noData" v-if="doorList.length==0">暂无报警</p>
              </div>
              <div class="data_charts">
                <div id="breadEcharts2"></div>
              </div>
            </div>
          </li>
          <li>
            <header>
              <p>设备掉线</p>
              <span>(最近一个月)</span>
            </header>
            <div class="dataBox">
              <div class="yeasList">
                <div v-for="(dropsItem,index) in DropsList" :key="index">{{dropsItem.roomNumber}}房</div>
                <p class="noData" v-if="DropsList.length==0">暂无掉线</p>
              </div>
              <div class="data_charts">
                <div id="breadEcharts3"></div>
              </div>
            </div>
          </li>
          <li>
            <header>
              <p>温度异常</p>
              <span>(实时)</span>
            </header>
            <div class="dataBox">
              <div class="yeasList">
                <div v-for="(item,index) in Temperature" :key="index">{{item.roomNumber}}房</div>
                <p class="noData" v-if="Temperature.length==0">暂无异常</p>
              </div>
              <div class="data_charts">
                <div id="breadEcharts4"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="dataBottom flex flex-1">
        <ul class="flex data_bottom">
          <li>
            <header>
              <p>PM2.5超标</p>
              <span>(实时)</span>
            </header>
            <div class="dataBox">
              <div class="yeasList">
                <div v-for="(PMItem,index) in PMList" :key="index">{{PMItem.roomNumber}}房</div>
                <p class="noData" v-if="PMList.length==0">暂无超标</p>
              </div>
              <div class="data_charts">
                <div id="breadEcharts5"></div>
              </div>
            </div>
          </li>
          <li class="informationCont flex flex-1">
            <header>工作单位信息</header>
            <div class="dataList flex flex-column flex-1">
              <div class="table">
                <div class="initiateTime">
                  <h3>发起时间/发单人</h3>
                </div>
                <div class="roomNumber">
                  <h3>房间号</h3>
                </div>
                <div class="orderType">
                  <h3>工单类型</h3>
                </div>
                <div class="orderDetails">
                  <h3>工单详情</h3>
                </div>
                <div class="head">
                  <h3>负责人</h3>
                </div>
                <div class="withStatus">
                  <h3>处理状态</h3>
                </div>
              </div>
              <div class="flex-1 tableBox">
                <div v-for="(work,index) in works" :key="index" class="contentList flex">
                  <div class="initiateTime">
                    <p>
                      {{work.createTime}}
                      <span class="initiateName">{{work.createUser}}</span>
                    </p>
                  </div>
                  <div class="roomNumber">
                    <p>{{work.roomNumber}}</p>
                  </div>
                  <div class="orderType">
                    <p>{{work.workType}}</p>
                  </div>
                  <div class="orderDetails">
                    <p style="color:red;">{{work.workParticulars}}</p>
                  </div>
                  <div class="head">
                    <p>{{work.principalUser}}</p>
                  </div>
                  <div class="withStatus">
                    <button>{{+work.status===0?'未处理':'已处理'}}</button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <header>
              <p>甲醛超标</p>
              <span>(实时)</span>
            </header>
            <div class="dataBox">
              <div class="yeasList">
                <div v-for="(item,index) in methanalList" :key="index">{{item.roomNumber}}房</div>
                <p class="noData" v-if="methanalList.length==0">暂无超标</p>
              </div>
              <div class="data_charts">
                <div id="breadEcharts6"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div id="bar_horizontal"></div> -->
  </div>
</template>

<script>
import {
  GET_WORK,
  GET_SOS_LIST,
  GET_Sensor,
  GET_Temperature,
  GET_Clean,
  GET_Drops,
} from "@/api";
import { breadData } from "@/api/common";
export default {
  // name: "informationManagement",
  data() {
    return {
      works: [],
      sosList: [],
      cleanNumList: [],
      DropsList: [],
      doorList: [],
      Temperature: [],
      PMList: [],
      methanalList: [],
      workData: {
        pageNum: 1,
        pageSize: 10,
      },
      
    };
  },
  mounted() {
    this.myChart0 = this.$echarts.init(
      document.getElementById("breadEcharts0")
    );
    this.myChart1 = this.$echarts.init(
      document.getElementById("breadEcharts1")
    );
    this.myChart2 = this.$echarts.init(
      document.getElementById("breadEcharts2")
    );
    this.myChart3 = this.$echarts.init(
      document.getElementById("breadEcharts3")
    );
    this.myChart4 = this.$echarts.init(
      document.getElementById("breadEcharts4")
    );
    this.myChart5 = this.$echarts.init(
      document.getElementById("breadEcharts5")
    );
    this.myChart6 = this.$echarts.init(
      document.getElementById("breadEcharts6")
    );
    // this.init();
    window.addEventListener("resize", () => {
      for (let i = 0; i < 7; i++) {
        this[`myChart${i}`].resize();
      }
    });
    // this.getWork1(this.workData)

    this.timingFun();
  },
  destroyed() {
     console.log('离开');
     window.clearInterval(this.initInterval)
   },
   
  methods: {
    // 获取工单 列表
    async getWork(params) {
      const res = await GET_WORK(params);
      this.works = res.data.data;
    },
    // 工单
    async getWork1(data) {
      const res = await GET_WORK(data);
    },
    // 获取 sos 报警
    async getSOSList(params) {
      const res = await GET_SOS_LIST(params);
      this.sosList = res.data.data.roomList;
      this.myChart0.setOption(breadData(res.data.data));
    },
    //门磁报警
    async getSensor(data) {
      const res = await GET_Sensor(data);
      this.doorList = res.data.data.list;
      this.myChart2.setOption(breadData(res.data.data));
    },
    //温度情况
    async getTemperature(data) {
      const res = await GET_Temperature(data);
      this.Temperature = res.data.data.list;
      this.myChart4.setOption(breadData(res.data.data));
    },
    //请求清理
    async getClean(data) {
      const res = await GET_Clean(data);
      this.cleanNumList = res.data.data.roomList;
      this.myChart1.setOption(breadData(res.data.data));
    },
    //设备掉线
    async getDrops(data) {
      const res = await GET_Drops(data);
      this.DropsList = res.data.data.roomList;
      this.myChart3.setOption(breadData(res.data.data));
    },
    init() {
      //  breadData();
      // this.myChart0.setOption(breadData(dataa));
      // this.myChart1.setOption(bread);
      // this.myChart2.setOption(bread);
      // this.myChart3.setOption(bread);
      // this.myChart4.setOption(bread);
      // this.myChart5.setOption(bread);
      // this.myChart6.setOption(bread);
    },
    timingFun() {
      function init() {
        this.getWork();
        this.getSensor();
        this.getTemperature();
        this.getSOSList();
        this.getClean();
        this.getDrops();
      }
      init.call(this);
      this.initInterval =  setInterval(() => {
        init.call(this);
      }, 10000);
    },
  },
   
};
</script>



<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@for $i from 0 to 7 {
  #breadEcharts#{$i} {
    width: 100%;
    height: 220px;
  }
}
.home {
  height: 100%;
  width: 100%;
}
.data_charts {
  width: 55%;
  margin-left: 5%;
  height: 220px;
}

.data_bottom {
  width: 100%;
}

.dataContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 10px;

  .dataTop,
  .dataBottom {
    width: 98%;
    margin: 20px auto 0;
    height: 50%;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    li {
      width: 20%;
      height: 100%;
      box-sizing: border-box;
      margin-left: 10px;
      background: #ffffff;
      box-shadow: 0px 4px 18px 1px rgba(0, 0, 0, 0.1);
      padding-bottom: 15px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      header {
        display: flex;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        //   background: skyblue;
        background: linear-gradient(to right, #5377ee 30%, #2246e6);
        background: -moz-linear-gradient(right, #5377ee, #2246e6); /*Mozilla*/
        // background:-webkit-gradient(linear,0 50%,100% 50%,from(#5377ee),to(#2246e6));/*Old gradient for webkit*/
        background: -webkit-linear-gradient(
          right,
          #5377ee,
          #2246e6
        ); /*new gradient for Webkit*/
        background: -o-linear-gradient(right, #5377ee, #2246e6); /*Opera11*/
        padding: 8px 0 8px 10px;
        border-radius: 5px 5px 0 0;
        font-weight: bold;
        align-items: flex-end;
        span {
          font-size: 12px;
          margin-bottom: 1px;
        }
      }

      .dataBox {
        width: 92%;
        margin: 10px auto 0;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-start;

        .yeasList {
          border: 1px solid #0000ff;
          border-radius: 5px;
          width: 40%;
          max-height: 35vh;
          height: 100%;
          text-align: center;
          overflow-y: auto;

          div {
            border-bottom: 1px solid skyblue;
          }
        }
      }
    }

    .informationCont {
      width: 60%;
      height: 100%;

      .dataList {
        width: 95%;
        margin: 15px auto 0;
        height: 100%;
        overflow: hidden;

        .table {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-color: rgb(223, 230, 243);
          border-radius: 5px 5px 0 0;

          + div {
            overflow-y: auto;
          }

          > div {
            flex: 1;
            min-height: 30px;
            line-height: 30px;
            padding: 3px;
            border-right: 1px solid #ffffff;
            box-sizing: border-box;
          }
        }
        .initiateName {
          word-wrap: break-word;
          white-space: nowrap;
        }
        .tableBox {
          width: 100%;
          overflow-y: auto;
          .contentList {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            background: rgb(241, 246, 254);
            border-bottom: 1px solid #ffffff;

            > div {
              flex: 1;
            }

            > div {
              min-height: 30px;
              line-height: 30px;
              padding: 3px;
              box-sizing: border-box;
            }
          }
        }

        .initiateTime {
          width: 140px;

          h3 {
            line-height: 15px;
          }

          p {
            line-height: 15px;
          }
        }

        .roomNumber {
          width: 90px;
        }

        .orderType,
        .orderDetails,
        .head {
          width: 120px;
        }

        .withStatus {
          width: 130px;
          text-align: center;

          button {
            color: #ffffff;
            background: red;
            border-radius: 15px;
            padding: 5px 20px;
            border: none;
            outline: none;
            text-align: center;
          }
        }

        h3 {
          width: 70px;
          text-align: center;
          word-break: break-all;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: black;
          margin: auto;
          text-align: center;
        }

        p {
          font-size: 12px;
          color: #3f3e3e;
          word-break: break-all;
          text-align: center;
          padding: 4px;
          box-sizing: border-box;
        }
      }
    }
  }
}
.noData {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // line-height: 30vh;
  font-size: 14px;
  color: skyblue;
}
</style>