var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "dataContent flex flex-column flex-1" }, [
      _c("div", { staticClass: "dataTop" }, [
        _c("ul", [
          _c("li", [
            _vm._m(0),
            _c("div", { staticClass: "dataBox" }, [
              _c(
                "div",
                { staticClass: "yeasList" },
                [
                  _vm._l(_vm.sosList, function (sos, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(sos.roomNumber) + "房"),
                    ])
                  }),
                  _vm.sosList.length == 0
                    ? _c("p", { staticClass: "noData" }, [_vm._v("暂无报警")])
                    : _vm._e(),
                ],
                2
              ),
              _vm._m(1),
            ]),
          ]),
          _c("li", [
            _vm._m(2),
            _c("div", { staticClass: "dataBox" }, [
              _c(
                "div",
                { staticClass: "yeasList" },
                [
                  _vm._l(_vm.cleanNumList, function (cleanItem, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(cleanItem.roomNumber) + "房"),
                    ])
                  }),
                  _vm.cleanNumList.length == 0
                    ? _c("p", { staticClass: "noData" }, [_vm._v("暂无请求")])
                    : _vm._e(),
                ],
                2
              ),
              _vm._m(3),
            ]),
          ]),
          _c("li", [
            _vm._m(4),
            _c("div", { staticClass: "dataBox" }, [
              _c(
                "div",
                { staticClass: "yeasList" },
                [
                  _vm._l(_vm.doorList, function (doorItem, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(doorItem.roomNumber) + "房"),
                    ])
                  }),
                  _vm.doorList.length == 0
                    ? _c("p", { staticClass: "noData" }, [_vm._v("暂无报警")])
                    : _vm._e(),
                ],
                2
              ),
              _vm._m(5),
            ]),
          ]),
          _c("li", [
            _vm._m(6),
            _c("div", { staticClass: "dataBox" }, [
              _c(
                "div",
                { staticClass: "yeasList" },
                [
                  _vm._l(_vm.DropsList, function (dropsItem, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(dropsItem.roomNumber) + "房"),
                    ])
                  }),
                  _vm.DropsList.length == 0
                    ? _c("p", { staticClass: "noData" }, [_vm._v("暂无掉线")])
                    : _vm._e(),
                ],
                2
              ),
              _vm._m(7),
            ]),
          ]),
          _c("li", [
            _vm._m(8),
            _c("div", { staticClass: "dataBox" }, [
              _c(
                "div",
                { staticClass: "yeasList" },
                [
                  _vm._l(_vm.Temperature, function (item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(item.roomNumber) + "房"),
                    ])
                  }),
                  _vm.Temperature.length == 0
                    ? _c("p", { staticClass: "noData" }, [_vm._v("暂无异常")])
                    : _vm._e(),
                ],
                2
              ),
              _vm._m(9),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "dataBottom flex flex-1" }, [
        _c("ul", { staticClass: "flex data_bottom" }, [
          _c("li", [
            _vm._m(10),
            _c("div", { staticClass: "dataBox" }, [
              _c(
                "div",
                { staticClass: "yeasList" },
                [
                  _vm._l(_vm.PMList, function (PMItem, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(PMItem.roomNumber) + "房"),
                    ])
                  }),
                  _vm.PMList.length == 0
                    ? _c("p", { staticClass: "noData" }, [_vm._v("暂无超标")])
                    : _vm._e(),
                ],
                2
              ),
              _vm._m(11),
            ]),
          ]),
          _c("li", { staticClass: "informationCont flex flex-1" }, [
            _c("header", [_vm._v("工作单位信息")]),
            _c("div", { staticClass: "dataList flex flex-column flex-1" }, [
              _vm._m(12),
              _c(
                "div",
                { staticClass: "flex-1 tableBox" },
                _vm._l(_vm.works, function (work, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "contentList flex" },
                    [
                      _c("div", { staticClass: "initiateTime" }, [
                        _c("p", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(work.createTime) +
                              "\n                    "
                          ),
                          _c("span", { staticClass: "initiateName" }, [
                            _vm._v(_vm._s(work.createUser)),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "roomNumber" }, [
                        _c("p", [_vm._v(_vm._s(work.roomNumber))]),
                      ]),
                      _c("div", { staticClass: "orderType" }, [
                        _c("p", [_vm._v(_vm._s(work.workType))]),
                      ]),
                      _c("div", { staticClass: "orderDetails" }, [
                        _c("p", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(work.workParticulars)),
                        ]),
                      ]),
                      _c("div", { staticClass: "head" }, [
                        _c("p", [_vm._v(_vm._s(work.principalUser))]),
                      ]),
                      _c("div", { staticClass: "withStatus" }, [
                        _c("button", [
                          _vm._v(
                            _vm._s(+work.status === 0 ? "未处理" : "已处理")
                          ),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("li", [
            _vm._m(13),
            _c("div", { staticClass: "dataBox" }, [
              _c(
                "div",
                { staticClass: "yeasList" },
                [
                  _vm._l(_vm.methanalList, function (item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(item.roomNumber) + "房"),
                    ])
                  }),
                  _vm.methanalList.length == 0
                    ? _c("p", { staticClass: "noData" }, [_vm._v("暂无超标")])
                    : _vm._e(),
                ],
                2
              ),
              _vm._m(14),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", [_vm._v("SOS报警")]),
      _c("span", [_vm._v("(最近一个月)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data_charts" }, [
      _c("div", { attrs: { id: "breadEcharts0" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", [_vm._v("请求清理")]),
      _c("span", [_vm._v("(最近一天)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data_charts" }, [
      _c("div", { attrs: { id: "breadEcharts1" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", [_vm._v("门磁报警")]),
      _c("span", [_vm._v("(实时)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data_charts" }, [
      _c("div", { attrs: { id: "breadEcharts2" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", [_vm._v("设备掉线")]),
      _c("span", [_vm._v("(最近一个月)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data_charts" }, [
      _c("div", { attrs: { id: "breadEcharts3" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", [_vm._v("温度异常")]),
      _c("span", [_vm._v("(实时)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data_charts" }, [
      _c("div", { attrs: { id: "breadEcharts4" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", [_vm._v("PM2.5超标")]),
      _c("span", [_vm._v("(实时)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data_charts" }, [
      _c("div", { attrs: { id: "breadEcharts5" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table" }, [
      _c("div", { staticClass: "initiateTime" }, [
        _c("h3", [_vm._v("发起时间/发单人")]),
      ]),
      _c("div", { staticClass: "roomNumber" }, [_c("h3", [_vm._v("房间号")])]),
      _c("div", { staticClass: "orderType" }, [_c("h3", [_vm._v("工单类型")])]),
      _c("div", { staticClass: "orderDetails" }, [
        _c("h3", [_vm._v("工单详情")]),
      ]),
      _c("div", { staticClass: "head" }, [_c("h3", [_vm._v("负责人")])]),
      _c("div", { staticClass: "withStatus" }, [
        _c("h3", [_vm._v("处理状态")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("p", [_vm._v("甲醛超标")]),
      _c("span", [_vm._v("(实时)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "data_charts" }, [
      _c("div", { attrs: { id: "breadEcharts6" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }